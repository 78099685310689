import logoHero from '../images/logo_hero_mobimed.svg';
import { ReactComponent as LogoHeader } from '../images/logo_mobimed.svg';
import heroImg from '../images/heroImgPatient.svg';
import logoOnly from '../images/logo_only.svg';

import alterCansel from '../images/visit_cancel.svg';
import alterDone from '../images/appointment_done.svg';
import { ReactComponent as AppStoreIcon } from '../images/appStore.svg';
import { ReactComponent as GooglePlayIcon } from '../images/googlePlay.svg';
import { ReactComponent as RuStoreIcon } from '../images/ruStore.svg';

import checkMark from '../images/check_mark.svg';
import mmkNoRecords from '../images/mmk_no_records.svg';

import alterCallDrDone from '../images/call_dr_done.svg';

import call from '../images/call_doc.svg';
import clinic from '../images/clinic.svg';
import mmk from '../images/mmk.svg';
import feedback from '../images/first_button_feedback.svg';

const custom = {
  defTitle: 'Test MobiMed',
  AppStoreIcon,
  GooglePlayIcon,
  RuStoreIcon,
  logoHero,
  LogoHeader,
  logoOnly,
  heroImg,

  alterDone,
  alterCansel,
  alterCallDrDone,
  mmkNoRecords,
  checkMark,

  availableLanguages: 'RU,EN,FR',
  countryCode: 'RU',

  firstButtons: { call, mmk, clinic, feedback },
  showLoginDoctorButton: true,
  authMenuItems: [
    'home',
    'events_and_visits',
    'mmk',
    'invoices',
    'directions',
    'drugs',
    'map',
    'calypsoForm',
  ],

  paidIsBowserWin: true,
  unAuthMenuItems: ['home', 'map'],
  showAddVisitComment: false,
  showModaleAnketa: true,
  isSimpleRegistrationForm: false,
  titlePromo: 'Контент',
};
export default custom;
